import { createStore } from 'redux';
 
// Import the Root Reducer
import rootReducer from './reducers/index';

// preloadedState will be passed in by the plugin
const preLoadedStateHandler = (preloadedState) => {
	return createStore(rootReducer, preloadedState);
};

export default preLoadedStateHandler;