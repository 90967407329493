import { combineReducers } from 'redux';

// Import other reducers
import SearchReducer from './search-reducer';

// Combine all reducers
const rootReducer = combineReducers({
  SearchReducer,
});

export default rootReducer;