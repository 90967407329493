module.exports = [{
      plugin: require('../plugins/gatsby-source-zms/plugins/gatsby-source-zms/gatsby-browser.js'),
      options: {"plugins":[],"useLang":false,"baseURL":"https://waltoncountyfltourism.com","models":["Content.Page","Components.Accordion","Components.Quicklink","Career.Opportunity","Community.Resource","Components.Gallery","Community.Callout","Components.Slider","Content.Category","Staff.Department","Masthead.Slide","Press.Clipping","Press.Release","Content.Menu","Content.Card","Staff.Person","Blog.Post","Message"],"pages":[{"type":"ContentPage","component":"/opt/atlassian/pipelines/agent/build/src/templates/ContentPage.js"},{"type":"StaffDepartment","pagePattern":["/staff-directory/"],"component":"/opt/atlassian/pipelines/agent/build/src/templates/StaffDepartment.js","pageSize":1,"overshoot":0,"listing":true},{"type":"CareerOpportunity","pagePattern":["/hospitality-employment-hub/"],"component":"/opt/atlassian/pipelines/agent/build/src/templates/CareerOpportunity.js","pageSize":1,"overshoot":0,"listing":true},{"type":"PressRelease","pagePattern":["/press/","/press/page-$page/"],"component":"/opt/atlassian/pipelines/agent/build/src/templates/PressReleaseAll.js","pageSize":12,"overshoot":2,"listing":true},{"type":"PressRelease","component":"/opt/atlassian/pipelines/agent/build/src/templates/PressReleaseGet.js","path":"/press/$permalink/"},{"type":"PressClipping","pagePattern":["/clippings/","/clippings/page-$page/"],"component":"/opt/atlassian/pipelines/agent/build/src/templates/PressClippingAll.js","pageSize":12,"overshoot":2,"listing":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Walton County Tourism","short_name":"WCT","start_url":"/","background_color":"#257CE1","theme_color":"#257CE1","display":"minimal-ui","icon":"src/images/fl.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4a9fd0db3109e7ecc1a7c4426999ea68"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-redux/gatsby-browser.js'),
      options: {"plugins":[],"pathToCreateStoreModule":"./src/state/createStore.js","serialize":{"space":0,"isJSON":true,"unsafe":false,"ignoreFunction":true},"cleanupOnClient":true,"windowKey":"__PRELOADED_STATE__"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
